export function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function addDays(dateTime, count_days = 0){
  return new Date(new Date(dateTime).setDate(dateTime.getDate() + count_days));
}

export function setCookie(name, value, options = {}) {

  let today = new Date();
  const yearExpirity = addDays(today, 365);

    options = {
      path: '/',
      expires: yearExpirity,
      // при необходимости добавьте другие значения по умолчанию
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
    
    document.cookie = updatedCookie;
  }
  
export function deleteCookie(name) {
    setCookie(name, "", {
      'max-age': -1,
      'domain': 'neearby.com'
    })
  }